button,
.btn {
  border: none;
  background: #2a913e;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8rem;
  padding: 1px 35px;
  cursor: pointer;
  border-radius: 2px;
  text-decoration: none;
  line-height: 22px;
}
