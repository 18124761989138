header {
  background-color: #6200ee;
  padding: 12px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  /*align-items: center;*/
}

header h1 {
  font-size: 20px;
  font-weight: 200;
}

.search {
  display: flex;
}

.search input {
  width: 13em;
  margin-right: 5px;
}

.title {
  text-decoration: none;
  color: #fff;
}
