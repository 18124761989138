.add-form {
  width: 800px;
  margin: 50px auto;
}

.add-form label {
  display: block;
  font-weight: bold;
  margin-top: 20px;
}

.add-form label small {
  font-weight: normal;
}

.add-form input,
.add-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-form button {
  padding: 10px;
  margin-top: 20px;
  width: 100%;
}
